import React from 'react';
import { useSignup } from '../hooks/useSignup';

export default function Signup() {
  const { email, success, loading, setEmail, handleSubmit } = useSignup();

  return (
    <>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center">
        <div className="lg:w-0 lg:flex-1">
          <h2
            className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl"
            id="newsletter-headline"
          >
            Get notified about new tutorials
          </h2>
          <p className="mt-3 max-w-3xl text-lg leading-6 text-gray-400">
            Join over 1,000 developers who receive React and JavaScript tutorials via email.
          </p>
        </div>
        <div className="mt-8 lg:mt-0 lg:ml-8">
          {success ? (
            <div className="mt-6 max-w-md text-center">
              <p className="text-lg leading-6 text-gray-400">Thanks for subscribing!</p>
              <p className="mt-3 text-md leading-6 text-gray-400">
                Please confirm your email address by clicking the link in the email I sent you.
              </p>
            </div>
          ) : (
            <>
              <form onSubmit={handleSubmit} className="sm:flex">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email-address"
                  required
                  value={email}
                  type="email"
                  autoComplete="email"
                  className="w-full px-5 py-3 border border-transparent placeholder-gray-500 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white sm:max-w-xs rounded-md"
                  placeholder="Enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                  <button
                    type="submit"
                    className="block w-full py-3 px-4 rounded-md shadow bg-blue-500 text-white font-medium hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 focus:ring-offset-gray-900"
                    disabled={loading}
                  >
                    {loading ? 'Submitting...' : 'Notify me'}
                  </button>
                </div>
              </form>
              <p className="mt-3 text-sm text-gray-400">No spam. Unsubscribe at any time.</p>
            </>
          )}
        </div>
      </div>
    </>
  );
}
