import React, { useState } from 'react';
import classes from './decoupleExample.module.scss';

const calculateExponent = (base, exponent) => base ** exponent;

const useExponentCalculator = () => {
  const [base, setBase] = useState(4);
  const [exponent, setExponent] = useState(4);
  const result = calculateExponent(base, exponent).toFixed(2);

  const handleBaseChange = (e) => {
    e.preventDefault();
    setBase(e.target.value);
  };

  const handleExponentChange = (e) => {
    e.preventDefault();
    setExponent(e.target.value);
  };

  return {
    base,
    exponent,
    result,
    handleBaseChange,
    handleExponentChange,
  };
};

export default () => {
  const {
    base,
    exponent,
    result,
    handleExponentChange,
    handleBaseChange,
  } = useExponentCalculator();

  return (
    <div className={classes.blueWrapper}>
      <input
        type="number"
        className={classes.base}
        onChange={handleBaseChange}
        placeholder="Base"
        value={base}
      />
      <input
        type="number"
        className={classes.exponent}
        onChange={handleExponentChange}
        placeholder="Exp."
        value={exponent}
      />
      <h1 className={classes.result}>{result}</h1>
    </div>
  );
};
