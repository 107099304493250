import { useState } from 'react';

export const useSignup = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // post contact to https://emailoctopus.com/api/1.6/lists/:listId/contacts
    setLoading(true);
    try {
      await fetch(`https://hfwpzgpupoayblncvhxi.functions.supabase.co/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
        }),
      }).then((res) => res.json());
      setSuccess(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    email,
    setEmail,
    handleSubmit,
    loading,
    success,
  };
};
