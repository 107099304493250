import React, { FC } from 'react';
import './styles.scss';

const CallStack: FC = () => {
  return (
    <div className="wrapper">
      <pre className="code-editor" id="code-main">
        <div className="toolbar">
          <svg xmlns="http://www.w3.org/2000/svg" width="54" height="14" viewBox="0 0 54 14">
            <g fill="none" fillRule="evenodd" transform="translate(1 1)">
              <circle cx="6" cy="6" r="6" fill="#FF5F56" stroke="#E0443E" strokeWidth=".5"></circle>
              <circle
                cx="26"
                cy="6"
                r="6"
                fill="#FFBD2E"
                stroke="#DEA123"
                strokeWidth=".5"
              ></circle>
              <circle
                cx="46"
                cy="6"
                r="6"
                fill="#27C93F"
                stroke="#1AAB29"
                strokeWidth=".5"
              ></circle>
            </g>
          </svg>
        </div>
        <div className="code">
          <pre>
            <span id="code-one">
              const addOne <span className="operator">=</span> (value){' '}
              <span className="operator">{'=>'}</span> value <span className="operator">+</span>{' '}
              <span className="number">1</span>;
            </span>
            <br />
            <span id="code-two">
              const addTwo <span className="operator">=</span> (value){' '}
              <span className="operator">{'=>'}</span>{' '}
              <span id="code-two-one">
                addOne(value <span className="operator">+</span> <span className="number">1</span>);
              </span>
            </span>
            <br />
            <span id="code-three">
              const addThree <span className="operator">=</span> (value){' '}
              <span className="operator">{'=>'}</span>{' '}
              <span id="code-three-two">
                addTwo(value <span className="operator">+</span> <span className="number">1</span>);
              </span>
            </span>
            <br />

            <span id="code-calc">
              const calculation = () <span className="operator">{'=>'}</span> {`{`}
              <br />
              &nbsp;&nbsp;<span className="keyword">return</span>{' '}
              <span id="code-calc-three">
                addThree(<span className="number">1</span>)
              </span>{' '}
              <span className="operator">+</span>{' '}
              <span id="code-calc-two">
                addTwo(<span className="number">2</span>);
              </span>
              <br />
              {`}`};
            </span>
            <br />

            <span id="code-calc-call">calculation();</span>
            <br />
          </pre>
        </div>
      </pre>

      <div className="call-stack">
        <div className="calls">
          <div className="call" id="call-one">
            addOne(3)
          </div>
          <div className="call" id="call-two">
            addTwo(2)
          </div>
          <div className="call" id="call-three">
            addThree(1)
          </div>
          <div className="call" id="call-calc">
            calculation()
          </div>
          <div className="call" id="call-main">
            main()
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallStack;

const states = [
  ['main'],
  ['main', 'code-calc-call', 'calc'],
  ['main', 'calc', 'code-calc-three', 'three'],
  ['main', 'calc', 'three', 'code-three-two', 'two'],
  ['main', 'calc', 'three', 'two', 'code-two-one', 'one'],
  ['main', 'calc', 'three', 'code-three-two', 'two'],
  ['main', 'calc', 'code-calc-three', 'three'],
  ['main', 'calc', 'code-calc-two', 'two'],
  ['main', 'calc', 'two', 'code-two-one', 'one'],
  ['main', 'calc', 'code-calc-two', 'two'],
  ['main', 'code-calc-call', 'calc'],
  ['main'],
  [],
];

const getEditorAndCallstackIds = (elementIds) => {
  const ids = [];
  elementIds.forEach((id, index) => {
    if (id.indexOf('code-') > -1) {
      // add id as is
      ids.push(id);
      return;
    }

    ids.push(`call-${id}`);
    if (index === elementIds.length - 1) {
      // only highlight last id in editor
      ids.push(`code-${id}`);
    }
  });

  return ids;
};

const addHighlightClass = (elementIds) => {
  if (typeof document === 'undefined') return;
  getEditorAndCallstackIds(elementIds).forEach((id) => {
    const element = document?.getElementById(id);
    if (!element) return;
    element.className = `${element.className} highlight`;
  });
};

const removeHighlightClass = (elementIds) => {
  if (typeof document === 'undefined') return;
  getEditorAndCallstackIds(elementIds).forEach((id) => {
    const element = document?.getElementById(id);
    if (!element) return;
    element.className = element.className.replace(' highlight', '');
  });
};

const applyStateIndex = (index) => {
  const state = states[index];
  let prevState;
  if (index > 0) {
    prevState = states[index - 1];
  } else {
    prevState = states[states.length - 1];
  }

  removeHighlightClass(prevState);
  addHighlightClass(state);
};

const applyIndexes = (index = 0) => {
  if (index > states.length - 1) {
    index = 0;
  }

  setTimeout(() => {
    applyStateIndex(index);
    applyIndexes(index + 1);
  }, 2000);
};

applyIndexes();
