import React from 'react';
import Button from '../../components/Button';
import styles from './LazyLoadingExample.module.scss';
const LazyComponent = React.lazy(() => import('./LazyComponent'));

const App = () => {
  const [show, setShow] = React.useState(false);
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h2>Click this button 👇</h2>
        <Button highlight onClick={() => setShow(!show)}>
          Load a lazy component
        </Button>
        <div className={styles.lazyComponent}>
          <React.Suspense fallback="Loading...">{show && <LazyComponent />}</React.Suspense>
        </div>
      </div>
    </div>
  );
};

export default App;
