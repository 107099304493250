import React, { FC } from 'react';
import styles from './styles.module.scss';
//import ThemeContext from '../../context/ThemeContext';

const ReactLocalStorageExample: FC = () => {
  //const { toggleDark } = React.useContext(ThemeContext);

  return (
    <div className={styles.wrapper}>
      <button className="dark-mode-toggle" onClick={() => void 0}>
        <img src="/logos/lightbulb.svg" style={{ height: '100px', width: '100px' }} />
        <h2>Click me!</h2>
      </button>
    </div>
  );
};

export default ReactLocalStorageExample;
