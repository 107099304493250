import React from 'react';

const { button, loadingStyle, highlightStyle, largeStyle } = {} as Record<string, string>;
export default ({
  onClick = () => undefined,
  className = '',
  children,
  type = 'button',
  loading = false,
  highlight = false,
  large = false,
}) => (
  <button
    className={`${button} ${className || ''} ${loading ? loadingStyle : ''} ${
      highlight ? highlightStyle : ''
    } ${large ? largeStyle : ''}`}
    onClick={loading ? () => void 0 : onClick}
    type={type as any}
    disabled={loading}
  >
    {loading ? 'Loading....' : children}
  </button>
);
