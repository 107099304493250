import React from 'react';
import styles from './reactMemoExample.module.scss';

const { blackTile, reactMemoWrapper, blueWrapper, update } = styles;
const Updates = ({ updates }) => (
  <h4 className={update}>
    Paints
    <br />
    {updates}
  </h4>
);

const Tile = () => {
  const eventUpdates = React.useRef(0);
  return (
    <div className={blackTile}>
      <Updates updates={eventUpdates.current++} />
    </div>
  );
};

const TileMemo = React.memo(
  () => {
    const updates = React.useRef(0);
    return (
      <div className={blackTile}>
        <Updates updates={updates.current++} />
      </div>
    );
  },
  (prev, next) => true,
);

export default () => {
  const updates = React.useRef(0);
  const [text, setText] = React.useState('');
  return (
    <div className={reactMemoWrapper}>
      <div className={blueWrapper}>
        <input
          value={text}
          placeholder="Write something"
          onChange={(e) => setText(e.target.value)}
        />
        <br />
        <Updates updates={updates.current++} />
        <Tile />
        <TileMemo />
      </div>
    </div>
  );
};
