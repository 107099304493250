import React from "react";
import styles from "./reactMemoExample.module.scss";

const { blackTile, reactMemoWrapper, blueWrapper, update } = styles;
const Updates = ({ updates }) => (
  <h4 className={update}>
    Paints
    <br />
    {updates}
  </h4>
);

const Tile = ({ children }) => {
  const eventUpdates = React.useRef(0);
  return (
    <div className={blackTile}>
      No memo
      <Updates updates={eventUpdates.current++} />
      {children}
    </div>
  );
};

const TileMemo = React.memo(
  ({ children }) => {
    const updates = React.useRef(0);
    return (
      <div className={blackTile}>
        Memo
        <Updates updates={updates.current++} />
        {children}
      </div>
    );
  },
  () => true
);

const InputSelfHandling = () => {
  const [text, setText] = React.useState("");
  return (
    <input
      value={text}
      placeholder="Write something"
      onChange={(e) => setText(e.target.value)}
    />
  );
};

export default ({ optimize }) => {
  const updates = React.useRef(0);
  const [text, setText] = React.useState("");
  return (
    <div className={reactMemoWrapper}>
      <div className={blueWrapper}>
        {optimize ? (
          <InputSelfHandling />
        ) : (
          <input
            value={text}
            placeholder="Write something"
            onChange={(e) => setText(e.target.value)}
          />
        )}
        <br />
        <Updates updates={updates.current++} />
        <Tile>
          <Tile>
            <Tile />
          </Tile>
        </Tile>
        {!!optimize && (
          <TileMemo>
            <TileMemo>
              <TileMemo />
            </TileMemo>
          </TileMemo>
        )}
      </div>
    </div>
  );
};
